
.full-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.settings {
    .body-content {
      background-color: #FFF;
    }
  }

  .body-content {
    display: flex;
    flex-direction: row;
    background-color: var(--container-background-color);
    flex: 1;
    overflow: hidden;

    .main {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;

      position: relative;

      > div:first-child {
        height: 100%;
        overflow-y: auto;
      }
    }
  }

}
